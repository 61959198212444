.aiChat {
  width: 100%;
  overflow: hidden;
  height: var(--chat-height);
  max-height: var(--max-height, unset);
  justify-content: space-between;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  border-radius: inherit;
}

/* Widgets like zone and section can toggle their visual separation. When the visual separation is turned on ( on either zone or section ),
  it adds a gutter padding around them. In order to account for the gutter top and bottom padding, we subtract 2x the gutter padding
  from the chat-height so that the widget does not overflow.

  So total height of widget = chat-height - gutter padding of section/zone ( 2x --outer-spacing-3 ) - gutter padding of canvas ( --outer-spacing-4 ).
  Note: in embed mode, then canvas has no paddings.

  Note that the gutter padding for section and zone is same, so it does not matter if the visual separation is on zone or section.
*/
:is(
    [elevation="1"][data-elevation="true"],
    [elevation="2"][data-elevation="true"]
  )
  .aiChat {
  --max-height: calc(var(--chat-height) - ((var(--outer-spacing-3) * 2)));
}

/** This is for the case when there is visual separation on both zone and section.
  In this case, we need to subtract 4x ( 2x top and 2x bottom since the gutter padding is on zone and section both)
  the gutter padding from the chat-height so that the widget does not overflow. This case overrides the above two cases.

  So total height of widget = chat-height - gutter padding of section - gutter padding of zone - gutter padding of canvas

  Since the gutter padding for section and zone is same, we use 4x the gutter padding ( 2x top + 2x bottom )
*/
[elevation="1"][data-elevation="true"]
  [elevation="2"][data-elevation="true"]
  .aiChat {
  --max-height: calc(var(--chat-height) - ((var(--outer-spacing-3) * 4)));
}

.header {
  padding: var(--inner-spacing-2) var(--inner-spacing-4);
  border-bottom: 1px solid var(--color-bd-elevation-1);
  background-color: transparent;
}

/**
 * ------------------------------------------------------------------------------------------------
 * SIDEBAR WRAPPER
 * ------------------------------------------------------------------------------------------------
 */
.sidebarWrapper {
  display: flex;
  width: 100%;
  min-height: 0;
  flex-grow: 1;
}

/**
 * ------------------------------------------------------------------------------------------------
 * CHAT HEADER
 * ------------------------------------------------------------------------------------------------
 */
.deleteButton {
  margin-left: auto;
}

.avatarImage {
  align-items: center;
  display: inline-flex;
  height: var(--icon-size-3);
  width: var(--icon-size-3);
  justify-content: center;
  flex-shrink: 0;
}

/**
 * ------------------------------------------------------------------------------------------------
 * MESSAGE LIST
 * ------------------------------------------------------------------------------------------------
 */
.messageList {
  overflow-y: auto;
}

.messageListItem:first-child {
  white-space: pre-wrap;
}

.messageListItem:first-child [data-markdown]::after,
.messageListItem:first-child [data-markdown]::before {
  content: unset;
}

.userMessage {
  background-color: var(--color-bg-neutral-subtle);
  padding: var(--inner-spacing-3) var(--inner-spacing-4) var(--inner-spacing-4)
    var(--inner-spacing-5);
  border-radius: var(--border-radius-elevation-3);
  white-space: pre-wrap;
}

/**
 * ------------------------------------------------------------------------------------------------
 * CHAT INPUT SECTION
 * ------------------------------------------------------------------------------------------------
 */

.chatField:has([data-input-prefix]) [data-input-prefix],
.chatField:has([data-input-suffix]) [data-input-suffix] {
  --icon-size: calc(
    var(--body-line-height) + var(--body-margin-start) + var(--body-margin-end) +
      var(--inner-spacing-3) * 2
  );
  --icon-offset: calc((var(--input-height) - var(--icon-size)) / 2);
  --icon-size: calc(
    var(--body-line-height) + var(--body-margin-start) + var(--body-margin-end) +
      var(--inner-spacing-3) * 2
  );
  --icon-offset: calc((var(--input-height) - var(--icon-size)) / 2);
  position: absolute;
}

/** The has selector is used here to add more specificity */
.chatField:has([data-input-prefix]) [data-input-prefix] {
  bottom: var(--icon-offset);
  left: var(--icon-offset);
  margin-inline-start: 0;
}

/** The has selector is used here to add more specificity */
.chatField:has([data-input-suffix]) [data-input-suffix] {
  bottom: round(up, var(--icon-offset), 0.5px);
  right: var(--icon-offset);
  margin-inline-end: 0;
}

.chatInput:is(textarea) {
  min-block-size: initial;
}

.disclaimer {
  color: var(--color-fg-neutral-subtle);
}

.disclaimer [data-icon] {
  align-self: self-start;
  flex-shrink: 0;
}

/**
 * ------------------------------------------------------------------------------------------------
 * CHAT DOCUMENT VIEWER
 * ------------------------------------------------------------------------------------------------
 */
.documentViewer {
  position: relative;
  height: 100%;
}

.documentViewerSpinner {
  position: absolute;
  top: var(--inner-spacing-4);
  left: 50%;
  transform: translateX(-50%);
}
