.textDocumentViewer {
  position: relative;
  padding: var(--inner-spacing-6);
}

.spinner {
  position: absolute;
  top: var(--inner-spacing-4);
  left: 50%;
  transform: translateX(-50%);
}
